<template>
  <div class="row">
    <div class="col-md-12 card p-0">
      <div class="card-body row p-0">
        <div class="col-sm-12">
          <my-filter :rules="rules" v-on:filter-change="updateFilter"></my-filter>
        </div>
        <div class="col-sm-12">
          <my-table :columnDefs="columnDefs" v-bind:data-rows="donates" :actions="actions" />
        </div>
      </div>
    </div>
    <el-dialog
      title="Thông tin đóng góp"
      :visible.sync="dialogDonateDetail"
      width="70%"
      top="5vh"
    >
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12">
          <div class="card">
            <div class="card-header">
              <h6 class="title">Thông tin khách hàng</h6>
            </div>
            <div class="card-body">
              <p>Mã người dùng:
                <a class="pull-right" :href="`/#/users/${donateDetail.user_id}`" >{{donateDetail.user_id}}</a>
              </p>
              <p>Tên đầy đủ: <b class="pull-right">{{donateDetail.name}}</b></p>
              <p>Tên: <b class="pull-right">{{donateDetail.first_name}}</b></p>
              <p>Họ: <b class="pull-right">{{donateDetail.last_name}}</b></p>
              <p>Email: <b class="pull-right">{{donateDetail.email}}</b></p>
              <p>Số điện thoại: <b class="pull-right">{{donateDetail.phone}}</b></p>
              <p>Giới tính: <b class="pull-right">{{donateDetail.gender ? 'Nam' : 'Nữ'}}</b></p>
              <p>Ngày sinh: <b class="pull-right">{{donateDetail.birthday}}</b></p>
              <p>Tỉnh/thành phố: <b class="pull-right">{{donateDetail.province}}</b></p>
              <p>Quận/huyện: <b class="pull-right">{{donateDetail.district}}</b></p>
              <p>Phường/xã: <b class="pull-right">{{donateDetail.ward}}</b></p>
              <p>Địa chỉ: <b class="pull-right">{{donateDetail.address}}</b></p>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
          <div class="card">
            <div class="card-header">
              <h6 class="title">Đóng góp #{{donateDetail.id}}</h6>
            </div>
            <div class="card-body">
              <p>Mã cuộc đua: <b class="pull-right">{{donateDetail.race_id}}</b></p>
              <p>Tên cuộc đua: <b class="pull-right">{{donateDetail.race_title}}</b></p>
              <p>Quỹ từ thiện: <b class="pull-right">{{donateDetail.charity_title}}</b></p>
              <p>Số tiền: <b class="pull-right">{{$util.formatMoney(donateDetail.money)}}</b></p>
              <p>Trạng thái:
                <b v-if="donateDetail.status" class="badge badge-success pull-right">Đã thanh toán</b>
                <b v-else class="badge badge-danger pull-right">Chưa thanh toán</b>
              </p>
              <p v-if="isImportFromOrder(donateDetail.payment_logs)">Đóng góp từ đơn hàng:
                <a class="pull-right" :href="`/#/orders/${donateDetail.payment_transaction_id}`" >{{ donateDetail.payment_transaction_id }}</a>
              </p>
            </div>
          </div>
          <div class="card" v-if="!isImportFromOrder(donateDetail.payment_logs)">
            <div class="card-header">
              <h6 class="title">Cổng thanh toán</h6>
            </div>
            <div class="card-body order-title">
              <div>
                <p>Cổng thanh toán: <b class="pull-right">{{ donateDetail.paygate_name }}</b></p>
                <p>Hình thức thanh toán: <b class="pull-right">{{ donateDetail.payment_name }}</b></p>
                <el-button class="pull-right" v-if="donateDetail.status == 0" type="success" @click="updateDonateStatus">Đã thanh toán</el-button>
                <div v-else>
                  <p v-if="donateDetail.payment_transaction_id">Mã giao dịch: <b class="pull-right">{{ donateDetail.payment_transaction_id }}</b></p>
                  <div v-if="donateDetail.payment_logs">
                    <p v-for="(value, key) in donateDetail.payment_logs"><span style="text-transform: capitalize;">{{ key }}</span>: <b class="pull-right">{{ value }}</b></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import { MessageBox, Message, Dialog, Button } from 'element-ui'
  import MyTable from 'src/components/UIComponents/Table.vue'
  import MyFilter from 'src/components/UIComponents/Filter.vue'
  import donateSchemas from './donate-schemas'
  import dtHelper from 'src/helpers/datatable'

  export default{
    components: {
      MyTable,
      MyFilter,
      ElButton: Button,
      ElDialog: Dialog
    },
    computed: {
      donates() {
        let rows = this.$store.state.donates;
        return dtHelper.filterByRules(rows, this.filterOutput);
      },
    },
    data() {
      return {
        filterOutput: [],
        columnDefs: dtHelper.buildColumDefs(donateSchemas),
        actions: [{
          type: 'primary',
          icon: 'el-icon-view',
          callback: this.detail
        }],
        filter: {},
        rules: dtHelper.buildRules(donateSchemas),
        dialogDonateDetail: false,
        donateDetail: {}
      }
    },
    mounted() {
      window.AP = this;
      this.$store.dispatch('fetchDonates');
      this.$store.dispatch('setPageTitle', 'Quản lý từ thiện');
      this.$store.dispatch('setCurrentActions', [])
    },
    methods: {
      isImportFromOrder(payment_logs) {
        if (payment_logs && typeof payment_logs == 'string' && payment_logs.indexOf('import from order') > -1) {
          return true;
        }
        return false;
      },

      detail(index, row) {
        this.donateDetail = Object.assign({}, row);
        this.dialogDonateDetail = true;
      },

      updateDonateStatus() {
        this.donateDetail.status = 1;
        this.donateDetail.donate_status = 1;
        this.$store.dispatch('updateDonateInfo', this.donateDetail).then(res => {
          Message({
            type: 'success',
            message: 'Cập nhật thành công'
          });
          let index = this.donates.findIndex(x => x.id == this.donateDetail.id);
          this.donates[index].donate_status = 1;
        });
      },

      updateFilter(filterOutput) {
        this.filterOutput = filterOutput;
      }
    },
    destroyed(){
      this.$store.dispatch('setCurrentActions', []);
    }
  }
</script>
